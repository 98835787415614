import PropTypes from 'prop-types'

import { Button } from '../ui-blocks'
import { FormField, Input, Textarea } from '../ui-blocks/forms'
import { isValidBasicUrl, FieldList } from '../../helpers/Form'


export const Projects = ({ projects, setProjects }) => {

    const addProject = () => {
        setProjects([
            ...projects.value,
            {
                _id: `_new_${Date.now()}`,
                name: '',
                documents: [{
                    _id: `_new_${Date.now()}`,
                    url: ''
                }
                ]
            }
        ])
    }

    const deleteProject = id => {
        setProjects(projects.value.filter(project => project._id !== id))
    }


    return (<div>
        <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
            <h2 className="fs-4 mb-0">Client Projects</h2>
            <Button variant="outline-primary" className="btn-sm" onClick={() => addProject()}>
                Add Project
            </Button>
        </div>

        <div className="d-flex flex-column gap-3">
            {projects.entries.map(projectFields => (
                <div key={projectFields._id.value} className="p-3 bg-white shadow-sm rounded-3">
                    <div className="d-flex flex-column gap-3">
                        <div className="d-flex align-items-end justify-content-between gap-4">
                            <FormField label="Title" className="flex-grow-1"
                                labelSuffix={
                                    (<button onClick={() => deleteProject(projectFields._id.value)}
                                        className="btn btn-link btn-sm on-hover-danger">
                                        delete
                                    </button>)
                                }>
                                <Input
                                    {...projectFields.name?.getStatusProps()}
                                    {...projectFields.name?.getProps()}
                                    className="form-control-lg rounded-2"
                                />
                            </FormField>
                        </div>
                        <div className="d-flex flex-column gap-3">
                            {
                                projectFields.documents.entries.map(documentFields => {

                                    const url = String(documentFields.url.value).trim()
                                    const isUrlValid = !!url && isValidBasicUrl(url)

                                    return (
                                        <FormField key={documentFields._id.value} label="URL"
                                            labelSuffix={isUrlValid &&
                                                (<a href={documentFields.url.value}
                                                    target="_blank" rel="noreferrer"
                                                    className="btn btn-link btn-sm">
                                                    view
                                                </a>)
                                            }>
                                            <Textarea
                                                {...documentFields.url?.getStatusProps()}
                                                {...documentFields.url?.getProps()}
                                                className="form-control"
                                                rows="6"
                                            />
                                        </FormField>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>)
}


Projects.propTypes = {
    projects: PropTypes.instanceOf(FieldList).isRequired,
    setProjects: PropTypes.func.isRequired
}
