import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'


import { TopNav } from '../components'
import { useForm } from '../helpers'

import { getFormToApi } from '../services/pmi-server-adapters'
import { getClient, saveClient } from '../services/pmi-server-api'

import { ClientDetails } from '../components/Clients'
import { Projects } from '../components/Projects'
import { SubmitButton } from '../components/ui-blocks'

import { projectFields } from '../components/Projects/project-fields'


export const EditProjects = () => {
    const [client, setClient] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const { clientId } = useParams()

    const [form, setFormValues] = useForm(projectFields)


    useEffect(() => {
        setIsLoading(true)

        getClient(clientId)
            .then(results => {

                setClient(results)
                setFormValues({ projects: results.projects })

                setIsLoading(false)
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const save = () => {
        setIsLoading(true)

        saveClient(clientId, getFormToApi(form.values))
            .then(results => {

                setClient(results)
                setFormValues({ projects: results.projects })

                setIsLoading(false)
            })
    }


    const setProjects = projects => {
        setFormValues({ projects })
    }


    if (!client) {
        return null
    }


    if (!Object.keys(form.values).length) {
        return null
    }


    return (<>
        <TopNav />

        <div className="container-lg">
            <div className="d-flex flex-column flex-md-row justify-content-between gap-3 mt-5">
                <div>
                    <ClientDetails client={client} />
                </div>
                <div className="ms-auto">
                    <SubmitButton onClick={save} isLoading={isLoading}>
                        Save
                    </SubmitButton>
                </div>
            </div>
            <div className="mb-5"></div>

            <Projects projects={form.fields.projects} setProjects={setProjects} />
        </div>
    </>)
}
