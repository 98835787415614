import React, { useState, createContext, useContext } from 'react'

import { getDecodedToken, getStoredToken, setStoredToken } from './auth'


const UserContext = createContext()
UserContext.displayName = 'UserContext'


export const useUser = () => {
    const userContext = useContext(UserContext)

    return {
        ...userContext
    }
}


export const UserProvider = ({ children }) => {
    const storedToken = getStoredToken()

    const [userToken, setUserToken] = useState(getDecodedToken(storedToken))


    const setUserTokenProxy = token => {
        setStoredToken(token)

        setUserToken(getDecodedToken(token))
    }


    const isAdmin = (userToken?.role === 'admin')

    return (
        <UserContext.Provider value={{
            userToken,
            setUserToken: setUserTokenProxy,
            isAdmin
        }}>
            {children}
        </UserContext.Provider>
    )
}
