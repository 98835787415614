import { Link } from 'react-router-dom'


const avatarSize = 40


export const ClientList = ({ clients }) => (

    <div className="list-group shadow-sm rounded-3">
        {
            clients.map(client => (
                <Link key={client.id} to={`/client/${client.id}`} className="list-group-item list-group-item-action">
                    <span className="d-flex gap-4">
                        <span className="d-none d-sm-block">
                            <img src={client.picture_uri} alt="" className="rounded-circle bg-light"
                                height={avatarSize} width={avatarSize}
                            />
                        </span>
                        <span className="d-flex flex-column flex-md-row justify-content-between flex-grow-1">
                            <span>
                                <span className="h5">{client.first_name} {client.last_name}</span>
                                <br className="d-block d-md-none" />
                                <small className="ms-md-3">{client.email}</small>
                                <br />
                                {!!client.projects?.length && (
                                    <span className="fs-5">
                                        <strong>{client.projects.length}</strong>
                                        <small className="text-muted">
                                            {` PROJECT${(client.projects.length > 1) ? 'S' : ''}`}
                                        </small>
                                    </span>
                                )}
                            </span>
                            {!!client.last_active_time && (
                                <span className="text-md-end small">
                                    <span className="text-muted">Last Access:&ensp;</span>
                                    {new Date(client.last_active_time).toLocaleDateString()}
                                </span>
                            )}
                        </span>
                    </span>
                </Link>
            ))
        }
    </div>

)
