export const getFormToApi = formValues => ({
    ...formValues,

    projects: formValues?.projects.map(project => ({
        ...getObjectRemovingNewId(project),

        documents: project?.documents?.map(document => ({
            ...getObjectRemovingNewId(document)
        }))

    }))
})


function getObjectRemovingNewId(obj) {
    if (!isNew(obj._id)) {
        return obj
    }

    const objWithoutId = { ...obj }
    delete (objWithoutId._id)

    return objWithoutId
}


function isNew(id) {
    return String(id).startsWith('_new_')
}
