import React from 'react'
import PropTypes from 'prop-types'


export const Textarea = ({ isInvalid = false, isDirty = false, className = '', ...props }) => {
    let inputClasses = `form-control ${className}`

    if (isInvalid) {
        inputClasses += ' is-invalid'
    }

    if (!isInvalid && isDirty) {
        inputClasses += ' border-success'
    }

    return (
        <textarea className={inputClasses} {...props}></textarea>
    )
}


Textarea.propTypes = {
    isInvalid: PropTypes.bool,
    isDirty: PropTypes.bool
}
