import React, { useEffect, useState } from 'react'

import { getClients } from '../services/pmi-server-api'

import { TopNav } from '../components'
import { ClientList } from '../components/Clients/ClientList'


export const Clients = () => {
    const [clients, setClients] = useState()

    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        setIsLoading(true)

        getClients()
            .then(results => {

                setClients(results)

                setIsLoading(false)
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    if (isLoading || !Array.isArray(clients)) {
        return null
    }

    return (<>
        <TopNav className="mb-sm-3" />

        <div className="container-lg no-margin-down-sm">
            <ClientList clients={clients} />
        </div>
    </>)
}
