import { IdField } from './IdField'
import { InputField } from './InputField'


export const createField = fieldOptions => {

    const factoryClass = fieldOptions.factoryClass || InputField

    return new factoryClass({ ...fieldOptions })
}


export const isField = field => (field instanceof IdField) || (field instanceof InputField)
