import React, { useEffect, useState } from 'react'

import { TopNav } from '../components'
import { Button } from '../components/ui-blocks'

import { useUser } from '../contexts'
import { getClient } from '../services/pmi-server-api'

import { ClientProjects as ClientProjectsComponent } from '../components/ClientProjects'


export const ClientProjects = () => {
    const [client, setClient] = useState()
    const [currentProject, setCurrentProject] = useState()
    const { userToken } = useUser()

    useEffect(() => {
        getClient(userToken.id)
            .then(results => {
                setClient(results)
            })
    }, [userToken.id])


    if (!client) {
        return null
    }

    if (!currentProject) {
        return (<>
            <TopNav className="mb-sm-3" />

            <div className="container-lg no-margin-down-sm">
                <ClientProjectsComponent projects={client.projects} setCurrentProject={setCurrentProject} />
            </div >
        </>)
    }


    return (<>
        <TopNav suffix={<Button onClick={() => setCurrentProject(null)} className="btn-sm">Close</Button>} />

        <iframe src={currentProject.documents[0].url} title={currentProject.name} className="full-screen loading-placeholder" />
    </>)
}
