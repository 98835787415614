export class IdField {

    constructor({ value, onChange }) {

        this.onChange = onChange || (() => { })

        this.value = value

        this.isValid = true
    }


    setValue = value => {
        this.value = value

        this.handleChange()
    }


    /**
     * @private
     */
    handleChange = () => {
        this.onChange(this)
    }


    set value(value) {
        this.idValue = value || getUniqueId()
    }


    /**
     * @return array
     */
    get value() {
        return this.idValue
    }


    toString = () => {
        return this.value
    }


    validate = () => {
        this.isValid = true
    }

}


function getUniqueId() {
    return String(Date.now()) + String(Math.floor(Math.random() * 1000))
}
