import { isRequired, isValidBasicUrl, IdField, FieldList } from '../../helpers/Form'


export const projectFields = {
    projects: {
        factoryClass: FieldList,
        fields: {
            _id: {
                factoryClass: IdField
            },
            name: {
                validators: [isRequired]
            },
            documents: {
                factoryClass: FieldList,
                fields: {
                    _id: {
                        factoryClass: IdField
                    },
                    url: {
                        validators: [isRequired, isValidBasicUrl]
                    }
                }
            }
        }
    }
}
