import jwt from 'jsonwebtoken'

const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN
const tokenKey = process.env.REACT_APP_USER_TOKEN_KEY
const tokenSecret = process.env.REACT_APP_TOKEN_SECRET


export function getDecodedToken(token) {
    if (!token) {
        return null
    }

    try {
        return jwt.verify(token, tokenSecret)
    } catch (e) {
        console.error(e)

        return null
    }
}


export const getStoredToken = () => getCookieValue(tokenKey)


export function setStoredToken(token) {
    document.cookie = `${tokenKey}=${token};path=/;max-age=31536000;domain=${cookieDomain}`
}


function getCookieValue(cookieName) {
    const b = document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`)

    return b ? b.pop() : ''
}
