export const ClientProjects = ({ projects, setCurrentProject }) => {

    if (!projects.length) {
        return (<div class="text-center mt-5">
            <h2>You have no Project assigned</h2>
            <img src={`${process.env.PUBLIC_URL}/icon-folder-open.svg`} alt="" width="100" />
        </div>)
    }


    return (
        <div className="list-group shadow-sm rounded-3">
            {
                projects.map(project => (
                    <button type="button" key={project._id} onClick={() => setCurrentProject(project)}
                        className="list-group-item list-group-item-action">
                        {project.name}
                    </button>
                ))
            }
        </div>
    )

}
