import { Routes, Route } from 'react-router-dom'

import { useUser } from './contexts'
import { Clients, ClientProjects, EditProjects } from './sections'


export const App = () => {
    const { isAdmin, userToken, setUserToken } = useUser()

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const login = urlParams.get('login')

    if (login) {
        setUserToken(login)

        if (userToken) {
            const newURL = window.location.href.split('?')[0]
            window.history.pushState('object', document.title, newURL)

            return null
        }
    }


    if (!userToken) {
        return null
    }


    if (isAdmin) {
        return (
            <Routes>
                <Route path="/client/:clientId" element={<EditProjects />} />
                <Route path="/" element={<Clients />} />
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path="/" element={<ClientProjects />} />
        </Routes>
    )
}
