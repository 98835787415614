const avatarSize = 76


export const ClientDetails = ({ client }) => (

    <div className="d-flex gap-4">
        <div className="d-none d-sm-block">
            <img src={client.picture_uri} alt="" className="rounded-circle bg-light"
                height={avatarSize} width={avatarSize}
            />
        </div>
        <div className="flex-grow-1">
            <h2>{client.first_name} {client.last_name}</h2>
            <p>
                {client.email}
            </p>
            {!!client.last_active_time && (
                <div className="small">
                    <span className="text-muted">Last Access:</span>&ensp;
                    {new Date(client.last_active_time).toLocaleDateString()}
                </div>
            )}
        </div>
    </div>

)
