import { Link } from 'react-router-dom'


export const TopNav = ({ suffix = null, className = '' }) => (
    <nav className={`navbar sticky-top navbar-light bg-white shadow-sm ${className}`}>
        <Link to="/" className="navbar-brand d-flex gap-2 align-items-center">
            <img src={`${process.env.PUBLIC_URL}/prizmed-imaging-logo.png`} alt="PRIZMED Imaging" height="24" />
            <span>Projects</span>
        </Link>
        {suffix}
    </nav>
)
