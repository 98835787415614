import { createField, isField } from './field-helper'


export class FieldList {

    constructor({ fields, value, onChange }) {

        /** @private object */
        this.fields = fields

        this.onChange = onChange || (() => { })

        this.value = Array.isArray(value) ? value : []

        this.isValid = true
    }


    setValue = value => {
        this.value = value

        this.handleChange()
    }


    /**
     * @private
     */
    handleChange = () => {
        this.validate()

        this.onChange(this)
    }


    /**
     * @param {array} values
     */
    set value(values) {
        this.entries = values.map(value => {
            const entry = {}

            Object.entries(this.fields).forEach(([fieldName, field]) => {
                if (isField(value[fieldName])) {
                    entry[fieldName] = value[fieldName]
                    return
                }

                entry[fieldName] = createField({
                    ...field,
                    value: (value && value[fieldName]) ?? field.value,
                    onChange: this.handleChange
                })
            })

            return entry
        })
    }


    /**
     * @return array
     */
    get value() {
        return this.entries.map(entry => {
            const values = {}

            Object.entries(entry).forEach(([fieldName, field]) => {
                values[fieldName] = field.value
            })

            return values
        })
    }


    validate = () => {
        this.isValid = true

        this.entries.forEach(entry => {

            Object.values(entry).forEach(field => {
                field.validate()

                if (!field.isValid) {
                    this.isValid = false
                }
            })

        })
    }

}
